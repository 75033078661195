const ru = {
  translation: {
    header: {
      "How it works?": "Как это работает?",
      FAQ: "FAQ",
      Partners: "Партнеры",
      Franchise: "Франшиза",
    },
    footer: {
      "Support service": "Служба поддержки",
      "User Agreement": "Пользовательское соглашение",
      INN: "ИНН",
      "ENERGO TECHNOLOGIES LLC": "ООО “ЭНЕРГО ТЕХНОЛОДЖИС”",
    },
    home: {
      "Rental service": "Павербанк",
      "of powerbanks": "рядом",
      "Charge on the go!": "Заряжай на ходу!",
      "How it works?": "Как это работает?",
      Install: "Установи",
      "the mobile app": "мобильное приложение",
      "Download on the": "Загрузите в",
      "Get it on": "Доступно в",
      Take: "Возьми",
      "a powerbank": "павербанк",
      "at the nearest terminal": "в ближайшем терминале",
      "Find the terminal in the application that is next to you. Scan the QR code or enter the number terminal.":
        "Найди в приложении терминал, который находится рядом с тобой. Отсканируй QR-код или введи номер терминала.",
      Charge: "Заряжай",
      "on the go": "на ходу",
      "and hand over in a convenient place": "и сдавай в удобном месте",
      "Take the powerbank with you and return it anywhere from the app.":
        "Возьми павербанк с собой и верни его в любой точке из приложения.",
      "Where are we located?": "Где нас найти?",
      "More than 1000 terminals throughout Moscow":
        "Более 1000 терминалов по всей Москве",
      "Transport hubs": "Транспортные узлы",
      "Fitness clubs": "Фитнес-клубы",
      "Central markets": "Центральные рынки",
      "Cafes and restaurants": "Кафе и рестораны",
      "Shopping malls": "Торговые центры",
      "All over the city": "По всему городу",
      "Find the nearest": "Найти ближайший",
      "Frequent questions": "Частые вопросы",
      "How to take the battery?": "Как взять аккумулятор?",
      "Log in to the app.": "Авторизуйтесь в приложении.",
      "Choose a convenient payment method.": "Выберите удобный способ оплаты.",
      "Find the nearest terminal on the map.":
        "Найдите ближайший терминал на карте.",
      "Scan the QR code on the terminal or enter its number manually.":
        "Отсканируйте QR-код на терминале или введите его номер вручную.",
      "After 10 seconds, remove the battery from the cell.":
        "Через 10 секунд заберите аккумулятор из ячейки.",
      "How much does the rent cost?": "Стоимость",
      "1 hour – 50 rubles.": "1 час – 50 рублей.",
      "1 day – 100 rubles.": "1 сутки – 100 рублей.",
      "Basic rate:": "Тариф базовый:",
      "50₽/first hour, then 100₽/per day":
        "50₽/первый час, затем 100₽/за сутки",
      "Please note that the cost may vary in different places and establishments.":
        "Обратите внимание, что в различных местах и заведениях стоимость может отличаться.",
      "The current rates are indicated on the terminals and in the application.":
        "Актуальные тарифы указаны на терминалах и в приложении.",
      "The money is debited from your card before the start of the rental and according to the time counter.":
        "Деньги списываются с вашей банковской карты до начала использования павербанка.",
      "How to return the battery?": "Как вернуть аккумулятор?",
      "Find the nearest terminal on the map in the app.":
        "Найдите ближайший терминал на карте в приложении.",
      "Click the “return battery“ button in the app.":
        "Нажмите в приложении кнопку “вернуть аккумулятор”.",
      "Insert the battery into a free cell.":
        "Вставьте аккумулятор в свободную ячейку.",
      "Which phones can be charged?": "Какие телефоны можно зарядить?",
      "Each battery has three cables:": "Каждый аккумулятор имеет три кабеля:",
      "How many batteries": "Сколько аккумуляторов",
      "can I take?": "я могу взять?",
      "You can take only one battery per account.":
        "На один аккаунт можно взять только один аккумулятор.",
      "You can take up to four powerbanks on one account.":
        "На один аккаунт можно взять до четырёх павербанков.",
      "Cooperation with us": "Сотрудничество с нами",
      "Contact the commercial department by number":
        "Обратитесь в коммерческий отдел по номеру",
      "On weekdays from 9:00 to 18:00": "В будни с 9:00 до 18:00",
      "Why do we charge for a day if the power bank charges for only 2 hours?":
        "Почему мы берём оплату за день, если павербанк заряжает всего 2 часа?",
      "The power bank charge is enough to fully charge the phone up to 100% in 2 hours (capacity 5000 mAh). The rates are calculated specifically so that you can safely hand over the powerbank at a convenient time and place for you.":
        "Заряда павербанка хватит, чтобы полностью зарядить телефон до 100% за 2 часа (ёмкость 5000 мАч). Тарифы рассчитаны специально, чтобы можно было спокойно сдать павербанк, в удобное вам время и место.",
      "What to do if": "Что делать, если",
      "The terminal is not working": "Терминал не работает",
      "Contact technical support by phone":
        "Обратитесь в техническую поддержку по телефону",
      "The QR code is not readable": "QR-код не считывается",
      "Try entering the number manually.": "Попробуйте ввести номер вручную.",
      "If you are in the subway, then enter 6 digits under the QR code.":
        "Если вы в метро, то введите 6 цифр под QR-кодом.",
      "If in an institution, then contact technical support by phone":
        "Если в заведении, то обратитесь в техническую поддержку по телефону",
      "The terminal has run out of batteries":
        "В терминале закончились аккумуляторы",
      "Find the nearest terminal in the application where there are free batteries.":
        "Найдите в приложении ближайший терминал, где есть свободные аккумуляторы.",
      "The lease started, but the terminal did not issue the battery":
        "Деньги списались с карты, но терминал не выдал аккумулятор",
      "Check the cell again – you may not have seen the issued battery. If nothing has happened, contact technical support by phone":
        "Проверьте ячейку еще раз – возможно, вы не увидели выданный аккумулятор. Если ничего не произошло, свяжитесь с технической поддержкой по телефону",
      "The rented battery does not work (the indicator does not light, the cable is torn off)":
        "Аккумулятор не работает (не горит индикатор, оторван кабель)",
      "Lost powerbank / damaged": "Потерял павербанк / повредил",
      "There is no access to the phone number and you need to hand over the battery?":
        "Нет доступа к номеру телефона и надо сдать аккумулятор?",
      "If you rent a powerbank to an institution, then just insert it into the terminal.":
        "Если вы сдаете павербанк в заведение, то просто вставьте его в терминал.",
      "If in the subway, then contact technical support by phone":
        "Если в метро, то обратитесь в техническую поддержку по телефону",
      "Any other questions?": "Остались вопросы?",
      "Write to us! We at EnerGO are all charged up!":
        "Напиши нам! Мы в EnerGO все заряжены!",
      "Ready to be always": "Готов быть всегда",
      "in touch?": "на связи?",
      "Download the mobile app": "Скачивай мобильное приложение",
      "from the App Store or Google Play": "из App Store или Google Play",
    },
    franchise: {
      Charge: "Заряжай",
      "with us!": "вместе с нами!",
      "Become a part": "Стань частью бизнеса",
      "of the sharing services business": "шеринговых услуг",
      "Make order": "Заказать",
      "Who are we and how does it all work?": "Кто мы и как это все работает?",
      "Service of short-term rent": "Cервис краткосрочной аренды",
      "of powerbanks for gadgets": "павербанков для гаджетов",
      "In our network": "В нашей сети",
      "there are 1000 terminals": "1000 терминалов",
      "in Moscow": "в Москве",
      including: "включая",
      "100 terminals": "100 терминалов",
      "inside the metro.": "внутри метро.",
      "The fully automated process of finding":
        "Полностью автоматизирован процесс поиска ",
      "the nearest place to rent a powerbank, payment":
        "ближайшего места аренды павербанка, оплата ",
      "and refund.": "и возврат.",
      "What do we offer?": "Что мы предлагаем?",
      "Stations with chargers": "Станции с зарядными устройствами",
      "4, 8, 24, 48 slots": "4, 8, 24, 48 слота",
      "Technical support": "Техподдержка",
      "We take over most of the work, you only need to arrange the stations":
        "Большую часть работы мы берем на себя, вам нужно только расставлять станции",
      Software: "Программное обеспечение",
      "Partner's personal account": "Личный кабинет партнера",
      "You will be able to see the service usage statistics":
        "Вы сможете видеть статистику использования сервиса",
      "Franchisee's Guide": "Руководство для франчайзи",
      "Instructions for setting up equipment, recommendations for placement locations":
        "Инструкции по настройке оборудования, рекомендации по местам расстановки",
      "Terminal formats": "Форматы терминалов",
      "1000+ terminals throughout Moscow": "1000+ терминалов по всей Москве",
      "8 slots": "8 слотов",
      "4 slots": "4 слота",
      "24 slots": "24 слота",
      "48 slots": "48 слотов",
      Size: "Размер",
      "Terminal for": "Терминал на",
      "Compact, ideal for:": "Компактный, идеально подходит для:",
      "The station is completely autonomous, only the presence of an outlet is required.":
        "Станция полностью автономна, требуется только наличие розетки.",
      "cafes, bars, restaurants": "кафе, баров, ресторанов",
      "beauty salons": "салонов красоты",
      "other establishments": "прочих заведений",
      "Send a request": "Оставить заявку",
      "coffee shops": "кофеен",
      barbershops: "барбершопов",
      markets: "рынков",
      universities: "ВУЗов",
      polyclinics: "поликлиник",
      "shopping centers": "торговых центров",
      airports: "аэропортов",
      "train stations": "вокзалов",
      "Franchise conditions": "Условия франшизы",
      "Return on investment from 9 months": "Возврат инвестиций от 9 месяцев",
      "Investments from 450,000 rubles": "Инвестиции от 450 000 руб",
      "Royalties 20%": "Роялти 20%",
      "Lump sum payment of 0 rubles": "Паушальный взнос 0 руб",
      "Revenue from the installation": "Выручка со дня установки",
      Calculator: "Калькулятор",
      "Select the terminal type": "Выберите тип терминала",
      "Enter the quantity": "Введите количество",
      "Your investment": "Ваши вложения",
      "Revenue in 12 months": "Ежемесячная выручка через 1 год",
      "Get an estimate": "Получить смету",
      "Left a request": "Оставил заявку",
      "What's next?": "Что дальше?",
      hours: "часа",
      "the time for which": "время, за которое",
      "we will contact you": "мы свяжемся с вами",
      call: "звонок",
      "we will answer all your questions and give you a personal offer":
        "ответим на все вопросы и дадим персональное предложение",
      days: "days",
      "the deadline for the conclusion": "срок для заключения",
      "of the contract": "договора",
      "Let's charge people together!": "Давай заряжать людей вместе!",
      "Send a request and get an offer for your city":
        "Оставь заявку и получи предложение для своего города",
      "Phone number": "Номер телефона",
      "First Last Name": "Имя Фамилия",
      Email: "Почта",
      City: "Город",
      "By clicking the button you agree to our":
        "Нажимая кнопку вы соглашаетесь с нашей",
      "privacy policy": "политикой конфиденциальности",
      "Charge on the go!": "Заряжай на ходу!",
      "Any other questions? Write to us!": "Остались вопросы? Напиши нам!",
      "We at EnerGO are all charged up!": "Мы в EnerGO все заряжены!",
      "Need a charging zone": "Нужна зона зарядки",
      "for an event?": "на мероприятие?",
      "Do you want to install our": "Хочешь установить наш ",
      "terminal in your establishment?": "терминал в своем заведении?",
      "EnerGo franchise": "Франшиза EnerGo",
      "Why choose us?": "Почему выбирают именно нас?",
      "Just manage. The key task is to install a charging station in a passageway and provide a second line of technical support.": "Просто управлять. Ключевая задача установить зарядную станцию в проходном месте и оказывать вторую линию техподдержки.",
      "The market is growing. Interest in the service is increasing every day.": "Рынок растет. С каждым днем интерес к сервису увеличивается.",
      "No lump-sum payment. You only pay for the equipment.": "Отсутствие паушального взноса. Вы платите только за оборудование.",
      "The opportunity to purchase a Master franchise. Manage a city, region or country!": "Возможность приобрести - Мастер франшизу. Управляй городом,регионом или страной!",
      "Quick profit - revenue from the first days of installation.": "Быстрый выход на прибыль - выручка с первых дней установки."
    },
  },
};

export default ru;
